<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<CartelSetting
			:user="user"

			class="flex-side mr-30"
		></CartelSetting>

		<div class="flex-1 bg-white pa-20 radius-20">

			<div class="under-line-dick pb-10 flex-row">
				<h3 class="flex-1">알림</h3>
			</div>
			<div class="mt-30">
				<div class="flex-row justify-space-between">
					<h5>공지글 알림</h5>
					<div class="cell_action" @click="postCartelPush('is_notice')">
						<p class="toggle-group">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle" :class="{ on: item_cartel.is_notice }"><em class="hide">공지글 알림</em></span>
						</p>
					</div>
				</div>

				<div class="color-gray">내 소식 알림 항목을 설정하세요.</div>

				<hr class="under-line mt-30">

				<div class="flex-row justify-space-between mt-30">
					<h5>댓글 알림</h5>
					<div class="cell_action" @click="postCartelPushComment('is_comment')">
						<p class="toggle-group">
							<!-- TODO : 수신 허용시 .on 클래스 추가 -->
							<span class="btn-toggle" :class="{ on: item_cartel.is_comment }"><em class="hide">댓글 알림</em></span>
						</p>
					</div>
				</div>

				<div class="color-gray">내 소식 알림 항목을 설정하세요.</div>

			</div>
		</div>
	</div>
</template>

<script>
	import CartelSetting from "@/view/Cartel/CartelSetting";
	export default {
		name: 'mafia076'
		,
		components: {CartelSetting},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: '카르텔 알림 설정'
					, title: '알림 설정'
					, not_footer: true
					, not_header: true
					, type: 'cartel_sub'
					, bg_contents: 'bg-gray01'
				}
				, item_cartel: {
					is_notice: true
					, is_comment: false
				}
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						if(this.item_cartel.comment_note_fg == 'Y'){
							this.item_cartel.is_comment = true
						}else{
							this.item_cartel.is_comment = false
						}
						if(this.item_cartel.notice_note_fg == 'Y'){
							this.item_cartel.is_notice = true
						}else{
							this.item_cartel.is_notice = false
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPushComment: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_comment_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, comment_note_fg: this.item_cartel.is_comment = !this.item_cartel.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postCartelPush: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_notice_alram
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_note_fg: this.item_cartel.is_notice = !this.item_cartel.is_notice ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						await this.getData()
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>